.react-datepicker {
  width: 100%;
  margin: 0 auto;
  border-radius: 0;
  border: 0;
  position: absolute !important;
}

.react-datepicker .react-datepicker__week.this-week {
  background-color: #f3f8ff;
  border-radius: 20px;
}

.react-datepicker .react-datepicker__header {
  background-color: #fff;
  border-bottom: 0;
  padding-top: 0;
}

.react-datepicker .react-datepicker__month-container {
  width: 100%;
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__current-month {
  position: relative;
  top: -3px;
  font-size: 14px;
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header__dropdown {
  padding-top: 14px;
  padding-bottom: 10px;
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header__dropdown
  select {
  color: #000;
  width: 80px;
  font-size: 12px !important;
  font-weight: 600;
  padding: 0 8px !important;
  height: 29px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
  min-width: 80px;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__month {
  margin: 0;
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__month-wrapper {
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 30px;
}

.react-datepicker .react-datepicker__month-container .react-datepicker__day,
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day-name,
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__time-name {
  width: calc(100% / 7);
  width: 36px;
  height: 36px;
  margin: 0 4px 0 1px;
  font-size: 12px;
  /* font-weight: 600; */
  line-height: 2.7;
  color: #3a3a3a;
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day-name:nth-last-child(1),
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day.saturday {
  color: #005dff;
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day-name:first-child,
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day.sunday,
.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day.holiday {
  color: #e02020;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--selected,
.react-datepicker .react-datepicker__day.react-datepicker__day--in-range {
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: #0060ff;
  color: #fff !important;
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day.react-datepicker__day--disabled {
  color: #eeeeee !important;
}

.react-datepicker .react-datepicker__day:hover {
  width: 36px;
  height: 36px;
  border-radius: 30px;
}

.react-datepicker .react-datepicker__navigation {
  top: 0;
  width: 28px !important;
  height: 28px !important;
  border-radius: 4px;
  border: solid 0.8px #dee2ec;
  background-color: #ffffff;
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: center center;
}

.react-datepicker
  .react-datepicker__navigation.react-datepicker__navigation--previous {
  left: 0;
  background-image: url("../icons/arrow.png");
}

.react-datepicker
  .react-datepicker__navigation.react-datepicker__navigation--next {
  right: 0;
  background-image: url("../icons/arrow_2.png");
}

.react-datepicker .react-datepicker__today-button {
  width: 55px;
  padding: 9px 10px 8px;
  margin: 10px auto 0;
  border-radius: 4px;
  border: solid 1px #dee2ec;
  background-color: #ffffff;
}

.react-datepicker-popper .react-datepicker__triangle {
  display: none;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  left: 0;
}

.react-datepicker-popper .react-datepicker {
  width: 310px;
  padding: 26px 12px;
  box-shadow: 0 4px 8px 0 rgb(222, 226, 236);
  border-radius: 2px;
  border: 1px solid #dee2ec;
}

.react-datepicker-popper .react-datepicker .react-datepicker__navigation {
  top: 20px;
}

.react-datepicker-popper
  .react-datepicker
  .react-datepicker__navigation.react-datepicker__navigation--previous {
  left: 12px;
}

.react-datepicker-popper
  .react-datepicker
  .react-datepicker__navigation.react-datepicker__navigation--next {
  right: 12px;
}

.react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__current-month {
  font-size: 17px;
  font-weight: 600;
}

.react-datepicker-popper .react-datepicker__aria-live {
  display: none;
}

.react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day-name:nth-last-child(1),
.react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day.saturday {
  color: #005dff;
}

.react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day-name:first-child,
.react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day.sunday,
.react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day.holiday {
  color: #e02020;
}

.react-datepicker__input-container {
  width: 100%;
  height: 100%;
}

.react-datepicker__input-container input[type="text"] {
  background-image: url("../icons/ic-absenteeschedule.png");
  background-size: 22px;
  background-position: right 5px center;
  background-repeat: no-repeat;
  outline: none;
}

.react-datepicker-wrapper input::placeholder {
  color: #2d2d2d !important;
  font-size: 12px;
}

.react-datepicker-wrapper .react-datepicker__aria-live {
  display: none;
}

.react-datepicker__navigation-icon {
  display: none;
}

.react-datepicker
  .react-datepicker__month-container
  .react-datepicker__day.react-datepicker__day--outside-month {
  color: #e6e6e6 !important;
}

.react-datepicker__day--today {
  font-weight: 500 !important;
  border: 1px solid #ff9900;
  border-radius: 30px;
}

.react-datepicker__day--today.react-datepicker__day--selected {
  border: 0 none;
}
